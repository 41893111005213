import React, { useState, useEffect } from "react";
import "./active-plans.scss";
import MainSection from "../main-section/main-section";
import { selectCurrentUser } from "../../redux/user/user.selectors";
import { UserDto, UserRole } from "../../dto/user-dto";
import { shallowEqual, useSelector } from "react-redux";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { UserRoleEnum } from "../../dto/user-role-enum";

const ActivePlans: React.FC = () => {
  const user = useSelector(selectCurrentUser, shallowEqual) as UserDto;
  const [modificators, setModificators] = useState([] as Array<UserRole>);

  useEffect(() => {
    calcModificators();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const calcModificators = () => {
    let modificatorsArray: Array<UserRole> = [];

    for (let i = 0; i < user.roles.length; i++) {
      let role = user.roles[i].role;
      if (!user.roles[i].stripePlan && role !== UserRoleEnum.TrialUser) {
        modificatorsArray.push(user.roles[i]);
      }
    }
    setModificators(modificatorsArray)
  }


  const rolePlanEnumToHumanLanguage = (roleEnum: UserRoleEnum): string => {
    if (roleEnum === UserRoleEnum.BasicUser) return "Basic";
    else if (roleEnum === UserRoleEnum.PlusUser) return "Plus";
    else if (roleEnum === UserRoleEnum.ProUser) return "Pro";
    else if (roleEnum === UserRoleEnum.Admin) return "Admin"
    else return UserRoleEnum[roleEnum];
  }

  return (
    <>
      {user && user.roles && user.roles.length > 0 ?
        <div className="active-plans">

          {user.rolePlan ?
            <>
              <MainSection
                smallTitle="Aktywna subskrybcja"
              />
              <Row className="active-plan-item">
                <Col xl={{ span: 7 }} className="roleName">
                  {rolePlanEnumToHumanLanguage(user.rolePlan!.role)}
                </Col>
                <Col xl={{ span: 3 }}>
                  Następny okres rozliczeniowy:
                </Col>
                <Col xl={{ span: 2 }} className="endDate">
                  {user.rolePlan!.endDate!.toString().split('T')[0]}
                </Col>
              </Row>
            </>
            : null}

          {modificators && modificators.length > 0 ?
            <>
              < MainSection
                smallTitle="Aktywne modyfikatory"
              />
              {modificators.map((role: UserRole, index: number) => {
                return (
                  <Row className="active-plan-item" key={index}>
                    <Col xl={{ span: 8 }} className="roleName">
                      {rolePlanEnumToHumanLanguage(role.role)}
                    </Col>
                    <Col xl={{ span: 2 }}>
                      Data ważności:
                    </Col>
                    <Col xl={{ span: 2 }} className="endDate">
                      {role.endDate ? role.endDate.toString().split('T')[0] : <>no limit</>}
                    </Col>
                  </Row>
                )
              })}
            </> : null
          }
        </div >
        : null}
    </>
  )
}

export default ActivePlans;