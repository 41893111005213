import { ApiMethodDto, UrlParam } from "./api-method-dto";
import { ToastsStore } from "react-toasts";
import { store } from "../redux/store";
import UserActionTypes from "../redux/user/user.types";
import CallbacksStateTypes from "../redux/callbacksState/callbacksState.types";

export type CallApiResponse = {
  data: any,
  details: Response
};

export async function callApi(method: string, path: string, options: ApiMethodDto): Promise<CallApiResponse> {
  const query = convertParamsToQuery(options.params);
  let res: any;

  try {
    res = await fetch(path + query, {
      mode: options.mode ? options.mode : undefined,
      method,
      headers: options.headers,
      body: options.datatype === "FormData" && options.body ? options.body : (options.body ? JSON.stringify(options.body) : null)
    })
  }
  catch (e) {
    // if (e.message === 'Failed to fetch') ToastsStore.error('Serwer nie odpowiada, błąd wczytywania danych - spróbuj odświeżyć za chwilę.');
    if (e.message === 'Failed to fetch'){
      store.dispatch({ type: CallbacksStateTypes.TOAST_FAILEDTOFETCH_ACTIVATED, payload: true });
      store.dispatch({ type: CallbacksStateTypes.TOAST_FAILEDTOFETCH_ACTIVATED, payload: false });
    } 
  }

  let returnRes: any;
  try {
    returnRes = await res.json()
  }
  catch{
    returnRes = await res;
  }
  
  if(returnRes === undefined) throw returnRes;

  if (Array.isArray(options.expectedStatus) && !(options.expectedStatus.indexOf(await res.status) === -1)) {
    return { data: returnRes, details: await res };
  }
  else if (await res.status === options.expectedStatus) {
    return { data: returnRes, details: await res };
  }

  if (returnRes.status === 204) {
    throw returnRes.status;
  }

  else if (await res.status === 401) {
    const unauthorizedToast = document.getElementsByClassName('unauthorizedToast');
    if (unauthorizedToast.length === 0) {
      if (options.noToast !== true) ToastsStore.error('Error ' + await res.status + ': ' + returnRes.error, undefined, 'unauthorizedToast');
    }

    store.dispatch({ type: UserActionTypes.USER_CLEAR_STORE });

    throw (returnRes.error)
  }
  else if (returnRes.error !== undefined) {
    if (options.noToast !== true) ToastsStore.error('Error ' + await res.status + ': ' + returnRes.error);
  }
  else {
    if (options.noToast !== true) ToastsStore.error('Something went wrong. Api-call-service: 29, ' + await res);
  }
  throw returnRes.error
}

function convertParamsToQuery(params?: Array<UrlParam> | UrlParam): string {
  let query: string = '';
  if (params) {
    query = "?"
    if (Array.isArray(params)) {
      const paramList = params as unknown as Array<UrlParam>
      paramList.map((param: UrlParam, index: number) => {
        query += param.name + "=" + param.value.toString() + (index < paramList.length ? "&" : "");
        return true;
      });
    }
    else {
      const param = params as unknown as UrlParam
      query += param.name + "=" + param.value.toString();
    }
  }
  return query;
}