import React, { useState, useEffect, useRef } from "react";
// import "./pytania-panel.scss";
import Helmet from "react-helmet";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import InputItem from "../../../../components/input-item/input-item";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { InputDto } from "../../../../dto/inputDto";
import { selectInputs } from "../../../../redux/inputsState/inputsState.selectors";
import InputItemCreator from "../../../../components/input-item/input-item-creator";
import { getAllInputs } from "../../../../redux/inputsState/inputsState.actions";
import { selectCurrentSuggestlyEntityId } from "../../../../redux/suggestlyEntityState/suggestlyEntityState.selectors";
import MainSection from "../../../../components/main-section/main-section";

const PytaniaPanel: React.FC<any> = () => {
  const [isNewInputCreatorEnabled, setSsNewInputCreatorEnabled] = useState(false);
  const inputs = useSelector(selectInputs, shallowEqual) as Array<InputDto>
  const currentSuggestlyEntityId = useSelector(selectCurrentSuggestlyEntityId, shallowEqual) as string

  const bottomRef = useRef<any>()
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllInputs());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentSuggestlyEntityId])

  useEffect(() => {
    if (isNewInputCreatorEnabled) {
      setTimeout(() => {
        bottomRef.current.scrollIntoView({ behavior: "smooth" })
      }, 10);
    }
    setSsNewInputCreatorEnabled(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputs])

  const handleAddNewInput = () => {
    setSsNewInputCreatorEnabled(true);
    setTimeout(() => {
      bottomRef.current.scrollIntoView({ behavior: "smooth" })
    }, 10);
  }

  return (
    <>
      <Helmet>
        <title>{'Pytania | Suggestly'}</title>
      </Helmet>
      <Container fluid={false}>
        <MainSection
          title="Pytania"
          subtitle="Tutaj skonfigurujesz i zdefiniujesz pytania z wariantami odpowiedzi. Pytania powinny dotyczyć kwestii, o które najczęściej pytają lub o które mogą pytać Twoi odbiorcy. Postaraj się, aby jak najwiecej zaprezentować graficznie."
        />
        {inputs ? inputs.map((input, index: number) => {
          return (
            <InputItem key={index} index={index} />
          )
        }) : null}
        {isNewInputCreatorEnabled ? <InputItemCreator index={inputs.length + 1} /> : null}
      </Container>
      <div className="circleAddButton_with_dropdown">
        <Button variant="primary" size="sm" onClick={handleAddNewInput}>
          <i className="fa fa-plus" aria-hidden="true"></i>	&nbsp;&nbsp;dodaj nowe pytanie
          </Button>
      </div>

      <div ref={bottomRef} />
    </>
  )
}

export default PytaniaPanel;