import React from 'react';

export const PrivacyPolicy = () => {
  return (
    <div className="policy">
      <p>
        Serwis posiada funkcję zbierania informacji o użytkownikach. Funkcja ta realizowana jest na 2 sposoby. Poprzez dobrowolne podanie informacji w formularzach oraz poprzez pliki cookies.
      </p>
      <p>
        Zgodnie z art.&nbsp;13 Rozporządzenia Parlamentu Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (RODO), informuje Pana/Panią, że:
      </p>
      <p>
        1. Administratorem przetwarzanych Pana/ Pani danych osobowych jest:
      </p>
      <p>
        XXXXXXXXXXXXXXXXXXXXXXXXXXXXX
      </p>
      <p>
        2. Dane osobowe przetwarzane są na podstawie art. 6 art. 6 ust.1a oraz art. 6 ust. 1b Rozporządzenia Parlamentu
        Europejskiego i Rady (UE) 2016/679 z dnia 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem
        danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (RODO).
      </p>
      <p>
        3. Podanie danych osobowych nie jest obowiązkowe, jednakże niepodanie danych wskazanych w formularzu powoduje
        brak możliwości wysłania newslettera. Przetwarzanie odbywa się na podstawie zgody wyrażanej przez Użytkownika oraz w przypadkach, w których przepisy prawa upoważniają Administratora do przetwarzania danych osobowych na podstawie przepisów prawa lub w celu realizacji zawartej pomiędzy stronami umowy.
      </p>
      <p>
        4. Posiada Pan/ Pani prawo do:
          <br />a. żądania dostępu do danych osobowych, ich sprostowania, usunięcia lub ograniczenia przetwarzania;
          <br />b. wniesienia sprzeciwu wobec przetwarzania, a&nbsp;także o&nbsp;prawie do przenoszenia danych;
          <br />c. prawie do cofnięcia zgody w&nbsp;dowolnym momencie bez wpływu na zgodność z&nbsp;prawem przetwarzania, którego dokonano na&nbsp;podstawie zgody&nbsp;przed jej cofnięciem;
          <br />d. wniesienia skargi do Urzędu Ochrony Danych.
      </p>
    </div>
  )
}