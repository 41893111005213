import React from "react";
import { RoutePath } from "../../../route-paths";
import { Switch, Route } from "react-router-dom";
import PanelSwitch from "./panel-page/panel-page-switch";
import SettingsSwitch from "./settings-page/settings-switch";
import "./authorized-page-switch.scss";
import PaymentCheckout from "./payment-checkout-page/payment-checkout";
import GlobalPreloader from "../../components/globalPreloader/globalPreloader";

const AuthorizedPageSwitch: React.FC = () => {
  return (
    <>
      <Switch>
        <Route path={RoutePath.HOME_PANEL} component={PanelSwitch} />
        <Route path={RoutePath.HOME_SETTINGS} component={SettingsSwitch} />
        <Route path={RoutePath.HOME_PAYMENTCHECKOUT} component={PaymentCheckout} />
        <Route component={PanelSwitch} />
      </Switch>
      <GlobalPreloader />
    </>
  );
}

export default AuthorizedPageSwitch;