import React, { useEffect } from "react";
import Helmet from "react-helmet";
import Container from "react-bootstrap/Container";
import ProductItem from "../../../../components/product-item/product-item";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import ProductItemCreator from "../../../../components/product-item/product-item-creator";
import { selectOutputs } from "../../../../redux/productState/productsState.selectors";
import { OutputDto } from "../../../../dto/outputDto";
import { getAllOutputs } from "../../../../redux/productState/productsState.actions";
import { OutputTypeEnum } from "../../../../dto/outputTypeEnum";
import LeadMagnetOutputItem from "../../../../components/product-item/leadMagnet-outputItem";
import MailListGrowthOutputItem from "../../../../components/product-item/mailListGrowth-outputItem";
import MainSection from "../../../../components/main-section/main-section";

const ProduktyPanel: React.FC<any> = () => {

  const outputs = useSelector(selectOutputs, shallowEqual) as Array<OutputDto>
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getAllOutputs());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <>
      <Helmet>
        <title>{'Produkty | Suggestly'}</title>
      </Helmet>
      <Container fluid={false}>
        <MainSection
          title="Sugestie"
          subtitle="Tutaj skonfigurujesz i dodasz własne scenariusze sugerujące. Czyli to, co będzie się wyświetlało na ekranach końcowych. Sugestią może być np. produkt, usługa lub dowolna inna rzecz, którą chcesz promować."
        />
        {outputs ? outputs.map((output, index: number) => {
          if (output.outputType === OutputTypeEnum.Affiliation) {
            return (
              <ProductItem key={index} index={index} />
            )
          }
          else if (output.outputType === OutputTypeEnum.MailListGrowth) {
            return (
              <MailListGrowthOutputItem key={index} index={index} />
            )
          }
          else if (output.outputType === OutputTypeEnum.LeadMagnet) {
            return (
              <LeadMagnetOutputItem key={index} index={index} />
            )
          }
          else {
            return null;
          }
        }) : null}
        <ProductItemCreator index={outputs.length + 1} />
      </Container>
    </>
  )
}

export default ProduktyPanel;