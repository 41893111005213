const WagesStateActionTypes = {
  UPDATE_WAGE: 'UPDATE_WAGE',  
  UPDATE_WAGE_SUCCESS: 'UPDATE_WAGE_SUCCESS',
  
  GET_ALL_CATEGORIESWAGES: 'GET_ALL_CATEGORIESWAGES',
  GET_ALL_CATEGORIESWAGES_SUCCESS: 'GET_ALL_CATEGORIESWAGES_SUCCESS',

  WAGES_CLEAR_STATE: 'WAGES_CLEAR_STATE',
};

export default WagesStateActionTypes;