

import React, { useEffect, useState } from "react";
import "./globalPreloader.scss";
import { PublicController } from "../../api/publicController";
import MicroSpinner from "../spinner/microSpinner";
import { useSelector, shallowEqual } from "react-redux";
import { selectIs_globalToastFailedToFetchActive } from "../../redux/callbacksState/callbacksState.selectors";
import { ToastsStore } from "react-toasts";

interface Props {
}

const GlobalPreloader: React.FC<Props> = (props: Props) => {
  const [isSpinner, setisSpinner] = useState(false);
  const [canBeActive, setCanBeActive] = useState(true);
  const isGlobalToastFailedToFetchActive = useSelector(selectIs_globalToastFailedToFetchActive, shallowEqual);

  const handleIsLive = () => {
    setisSpinner(true);
    var subscribePromise = PublicController.IsLive();
    subscribePromise.then((result) => {
      if (result === true) {
        setisSpinner(false);
      } else {
        handleIsLive();
      }
    }, reason => {
      setTimeout(() => {
        handleIsLive();
      }, 1000);
    })
  }

  useEffect(() => {
    console.log("booom");
    if (isGlobalToastFailedToFetchActive && canBeActive) {
      setCanBeActive(false);
      ToastsStore.error('Serwer nie odpowiada, błąd wczytywania danych - spróbuj odświeżyć za chwilę.');
      setInterval(() => {
        setCanBeActive(true);
      }, 10000);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isGlobalToastFailedToFetchActive])
  // eslint-enable-next-line react-hooks/exhaustive-deps

  useEffect(() => {



    handleIsLive();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  // eslint-enable-next-line react-hooks/exhaustive-deps


  return (
    <div className={isSpinner ? 'globalPreloader isSpinner' : 'globalPreloader'}>
      <MicroSpinner isVisible={isSpinner} />
      <h6>Trwa łączenie, proszę czekać</h6>
    </div>
  )
}

export default GlobalPreloader;
