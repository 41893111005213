import React, { useEffect, useState } from "react";
import Helmet from "react-helmet";
import Container from "react-bootstrap/Container";
import Button from "react-bootstrap/Button";
import Card from "react-bootstrap/Card";
import CircuralChart from "../../../../components/circular-chart/circural-chart";
import { getClientActions } from "../../../../redux/suggestlyEntityState/suggestlyEntityState.actions";
import { useDispatch, shallowEqual, useSelector } from "react-redux";
import { selectClientActionsSum, selectCurrentSuggestlyEntityId } from "../../../../redux/suggestlyEntityState/suggestlyEntityState.selectors";
import { Row } from "react-bootstrap";
import Col from "react-bootstrap/Col";
import { selectCurrentUser, selectSuggestlyEntities } from "../../../../redux/user/user.selectors";
import { UserRoleEnum } from "../../../../dto/user-role-enum";
import "./home-panel.scss";
import { RoutePath } from "../../../../../route-paths";
import { Link } from "react-router-dom";
import MainSection from "../../../../components/main-section/main-section";
import ConfigurationRequirements from "./configuration-requirements";

const HomePanel: React.FC<any> = () => {
  const suggestlyEntites = useSelector(selectSuggestlyEntities, shallowEqual);
  const [suggestlyEntityName, setSuggestlyEntityName] = useState('');
  const clientActionsSum = useSelector(selectClientActionsSum, shallowEqual);
  const currentUser = useSelector(selectCurrentUser, shallowEqual);
  const dispatch = useDispatch();
  const currentSuggestlyEntityId = useSelector(selectCurrentSuggestlyEntityId, shallowEqual);

  useEffect(() => {
    setSuggestlyEntityName(suggestlyEntites.find(x => x.id === currentSuggestlyEntityId) ? suggestlyEntites.find(x => x.id === currentSuggestlyEntityId)!.name : "default");
  }, [currentSuggestlyEntityId, suggestlyEntites])


  useEffect(() => {
    dispatch(getClientActions());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getMaxResults = () => {
    let allRoles = currentUser.roles.map(x => x.role);

    if (allRoles.indexOf(UserRoleEnum.Admin) > -1) {
      return 300000;
    }
    else if (allRoles.indexOf(UserRoleEnum.ProUser) > -1) {
      return 30000;
    }
    else if (allRoles.indexOf(UserRoleEnum.PlusUser) > -1) {
      return 10000;
    }
    else if (allRoles.indexOf(UserRoleEnum.BasicUser) > -1) {
      return 5000;
    }
    else return 1000;
  }

  const maxResults: number = Number((clientActionsSum.results * 100 / getMaxResults()).toFixed(0));

  const quelityOfResults_leads =
    clientActionsSum.results > 0 ? Number((clientActionsSum.newLeads * 100 / clientActionsSum.results).toFixed(1)) : 0;

  const quelityOfResults_redirects =
    clientActionsSum.results > 0 ? Number((clientActionsSum.redirects * 100 / clientActionsSum.results).toFixed(1)) : 0;

  return (
    currentUser && currentUser.isSignUpTutorialActive ? <div>sign up tutorial</div> : <div className="home-panel">
      <Helmet>
        <title>{'Suggestly'}</title>
      </Helmet>
      <Container>
        <ConfigurationRequirements />
        <MainSection
          title={"Statystyki projektu " + suggestlyEntityName}
          subtitle="Znajdziesz tu szczegóły, dotyczące użycia i skuteczności Twojego projektu"
        ></MainSection>
        <Row>
          <Col xl={{ span: 4 }}>
            <Card className="chartCard">
              <Card.Header>
                Wykorzystanie planu cenowego
              </Card.Header>
              <Card.Body>
                <div className="chartWrapper">
                  <CircuralChart val={maxResults} />
                </div>
                <br />
                <p>Zrealizowanych sugestii: <strong>{clientActionsSum ? clientActionsSum.results : 0}</strong></p>
                <p>Dostępnych w planie: <strong>{getMaxResults()}</strong></p>
                <p>Pozostało: <strong>{clientActionsSum ? (getMaxResults() - clientActionsSum.results) : 0}</strong></p>

                {maxResults >= 85 ?
                  <Link to={RoutePath.SUBSCRIPTION_SETTINGS}><Button variant="secondary">Zwiększ</Button></Link>
                  : null
                }
              </Card.Body>
            </Card>
          </Col>
          <Col xl={{ span: 4 }}>
            <Card className="chartCard">
              <Card.Header>
                Skuteczność zdobywania kontaktów
              </Card.Header>
              <Card.Body>
                <div className="chartWrapper">
                  <CircuralChart val={quelityOfResults_leads} />
                </div>
                <br />
                <p>Dane na bieżący miesiąc:</p>
                <p>Łącznie nowych kontaktów: <strong>{clientActionsSum ? clientActionsSum.newLeads : 0}</strong></p>
              </Card.Body>
            </Card>
          </Col>
          <Col xl={{ span: 4 }}>
            <Card className="chartCard">
              <Card.Header>
                Skuteczność przekierowań
              </Card.Header>
              <Card.Body>
                <div className="chartWrapper">
                  <CircuralChart val={quelityOfResults_redirects} />
                </div>
                <br />
                <p>Dane na bieżący miesiąc:</p>
                <p>Łącznie przekierowań: <strong>{clientActionsSum ? clientActionsSum.redirects : 0}</strong></p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        {/* 
        <ActivePlans />
        <Row>
          <Col xl={{ span: 12 }}>
            <Card className="chartCard">
              <Card.Header>
                Twój aktualny plan
              </Card.Header>
              <Card.Body>
                <div>
                  <h3>Free</h3>
                  <Link to={RoutePath.SUBSCRIPTION_SETTINGS}>
                    <Button variant="primary">Ustawienia subskrybcji</Button>
                  </Link>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row> */}
      </Container>
    </div>
  )
}

export default HomePanel;