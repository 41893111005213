import React from "react";
import Helmet from "react-helmet";
import "./signinup-page.scss";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Card from "react-bootstrap/Card";
import { Link } from "react-router-dom";
import { RoutePath } from "../../../route-paths";
import SignInBg from "./../../assets/signInBg.svg";
import ResetPasswordForm from "../../components/sign-upin-form/reset-password-form";

const ResetPasswordPage: React.FC<any> = () => {
  return (
    <div className="signinup-page">
      <Helmet>
        <title>{'Suggestly | Reset hasła'}</title>
      </Helmet>
      <Row>
        <Col xl={5}>
          <Card className="signInForm">
            <h5>Reset hasła</h5>
            <ResetPasswordForm />
            <div className="sign-inup-nav">
              <Link to={RoutePath.SIGNIN}>Wróć</Link>
            </div>
          </Card>
        </Col>
        <Col xl={7}>
          <img src={SignInBg} alt="" />
        </Col>
      </Row>
    </div>
  )
}

export default ResetPasswordPage;