import { takeLatest, put, all, call, select } from "redux-saga/effects";
import { ToastsStore } from "react-toasts";
import { ApiMethodDto, UrlParam } from "../../api/api-method-dto";
import { ApiPath } from "../../api/apiPath";
import { callApi } from "../../api/api-call-service";
import { SagaInputDto } from "../../api/saga-input-dto";
import WagesStateActionTypes from "./wagesState.types";
import { InputWageDto } from "../../dto/wageDto";
import { SuggestlyStore } from "../store.types";

function* getAllInputsWagesAsync() {
  const token = yield select(state => state.user.token);
  const suggestlyEntityId = yield select((state: SuggestlyStore) => state.suggestlyEntityState.currentEntitySuggestlyId);

  const options = {
    headers: { Authorization: token },
    method: "GET",
    expectedStatus: 200,
    params: {
      name: "suggestlyEntityId",
      value: suggestlyEntityId
    } as UrlParam
  } as ApiMethodDto;

  try {
    const res = yield call(callApi, "get", ApiPath.wages_getAllInputsWages, options);

    yield put({
      type: WagesStateActionTypes.GET_ALL_CATEGORIESWAGES_SUCCESS,
      payload: res.data as Array<InputWageDto>
    });
  } catch (error) {
    console.error('Something went wrong. ', error)
  }
}

function* updateWageAsync(sagaInput_name: SagaInputDto<{ wageId: string, value: number }>) {
  const token = yield select(state => state.user.token);
  const options = {
    headers: { Authorization: token },
    method: "GET",
    expectedStatus: 200,
    params: [{
      name: "wageId",
      value: sagaInput_name.payload.wageId
    }, {
      name: "value",
      value: sagaInput_name.payload.value
    }] as Array<UrlParam>
  } as ApiMethodDto;

  try {
    //const res = yield call(callApi, "get", ApiPath.wages_updateWage, options); // TODO update powinien iść z res, a nie z ponizszej akcji, bo zaciaga cale drzewo...
    yield call(callApi, "get", ApiPath.wages_updateWage, options);

    yield put({
      type: WagesStateActionTypes.GET_ALL_CATEGORIESWAGES
    });

    // yield put({
    //   type: WagesStateActionTypes.UPDATE_WAGE_SUCCESS,
    //   payload: res.data as WageDto
    // });

    ToastsStore.success('saved');

  } catch (error) {
    console.error('Something went wrong. ', error)
  }
}

function* watchUpdateWate() {
  yield takeLatest(WagesStateActionTypes.UPDATE_WAGE as any, updateWageAsync);
}

function* watchGetAllInputsWages() {
  yield takeLatest(WagesStateActionTypes.GET_ALL_CATEGORIESWAGES as any, getAllInputsWagesAsync);
}

export default function* WagesStateSagas() {
  yield all([
    call(watchUpdateWate),
    call(watchGetAllInputsWages)]);
}