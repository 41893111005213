import React, { useState, useEffect } from "react";
import "./kalibracja-panel.scss";
import Helmet from "react-helmet";
import Container from "react-bootstrap/Container";
import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { getAllWages } from "../../../../redux/wagesState/wagesState.actions";
import { selectInputsWages } from "../../../../redux/wagesState/wagesState.selectors";
import { InputWageDto } from "../../../../dto/wageDto";
import Button from "react-bootstrap/Button";
import Jumbotron from "react-bootstrap/Jumbotron";
import { RoutePath } from "../../../../../route-paths";
import { Link } from "react-router-dom";
import KalibracjaSimple from "./kalibracja-simple";
import Switch from "../../../../components/switch/switch";
import MainSection from "../../../../components/main-section/main-section";
import SwitchWithLabels from "../../../../components/switch/switch-with-labels";
import KalibracjaAdvanced from "./kalibracja-advanced";
import { getAllOutputs } from "../../../../redux/productState/productsState.actions";
import { getAllInputs } from "../../../../redux/inputsState/inputsState.actions";


const KalibracjaPanel: React.FC<any> = () => {

  const inputsWages = useSelector(selectInputsWages, shallowEqual) as Array<InputWageDto>

  const dispatch = useDispatch();
  const [firstLoad, setfirstLoad] = useState(true);
  const [isAdvanced, setIsAdvanced] = useState(false);

  useEffect(() => {
    dispatch(getAllOutputs());
    dispatch(getAllInputs());
    dispatch(getAllWages());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (firstLoad && inputsWages && inputsWages.length > 0) {
      setfirstLoad(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputsWages])

  return (
    <>
      <div className="kalibracja-panel">
        <Helmet>
          <title>{'Kalibracja | Suggestly'}</title>
        </Helmet>
        <Container fluid={false}>
          {inputsWages ? (inputsWages.length > 0 && inputsWages[0].outputs && inputsWages[0].outputs.length > 0 && inputsWages.filter(x => x.variants && x.variants.length === 0).length === 0 ?
            <>
              <MainSection
                title="Kalibracja"
                subtitle="Proces polega na ustawieniu odpowiednich wag, dla wszystkich wariantów odpowiedzi w zależności od typu sugestii. Im wyższa waga, tym wyższe prawdopodobieństwo, że zostanie wybrany dany produkt."
              >
                <SwitchWithLabels
                  rightLabel={<>Zaawansowany</>}
                  leftLabel={<>Uproszczony</>}
                  isLeftActive={isAdvanced}
                >
                  <Switch
                    inText
                    checked={isAdvanced}
                    onChange={() => setIsAdvanced(!isAdvanced)}
                  >
                  </Switch>
                </SwitchWithLabels>
              </MainSection>
              {isAdvanced ? <KalibracjaAdvanced /> : <KalibracjaSimple />}
            </>
            :
            <>
              <Jumbotron className="delay-loading">
                <h2>Nie można przeprowadzić kalibracji</h2>
                <br />
                <p>
                  Aby możliwa była pełna kalibracja, niezbędne są 2 warunki:
                </p>
                <ul>
                  <li>zdefiniowane minimum jedno pytanie z wariantami odpowiedzi</li>
                  <li>zdefinowana minimum jedna sugestia np. produkt lub usługa</li>
                </ul>
                <br />
                <div className="fastNav">
                  {inputsWages.length > 0 ? (
                    inputsWages[0].variants && inputsWages[0].variants.length > 0 ? (
                      inputsWages.find(x => !(x.variants && x.variants.length > 0)) ? <Link to={RoutePath.PYTANIA_PANEL}>
                        <Button variant="secondary">Każde pytanie powinno mieć zdefiniowane warianty odpowiedzi</Button>
                      </Link> : null
                    ) :
                      <Link to={RoutePath.PYTANIA_PANEL}>
                        <Button variant="secondary">Uzupełnij warianty</Button>
                      </Link>
                  ) :
                    <Link to={RoutePath.PYTANIA_PANEL}>
                      <Button variant="secondary">Uzupełnij pytania</Button>
                    </Link>
                  }
                  {inputsWages.length > 0 && inputsWages[0].outputs && inputsWages[0].outputs.length > 0 ? null :
                    <Link to={RoutePath.PRODUKTY_PANEL}>
                      <Button variant="primary">Uzupełnij sugestie</Button>
                    </Link>
                  }
                </div>
                <br />
                <div className="fastNav fastFooter">
                  <p>
                    Jeśli nie masz pewności co należy zrobić, skontaktuj się z administracją.
                  </p>
                  <Link to={RoutePath.CONTACT_SETTINGS} className={"supportBtn"}>
                    <Button variant="link" className={"supportBtn"} >Pomoc</Button>
                  </Link>
                </div>
              </Jumbotron></>) : null}
        </Container>
      </div>
    </>
  )
}

export default KalibracjaPanel;