import "./App.scss";
import "./argon/argon-design-system-react.scss";
import "./argon/vendor/font-awesome/css/font-awesome.min.css";
import React from "react";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import { History } from 'history';
import CookiePolicyPopup from "./framework/components/cookie-policy-popup/cookie-policy-popup";
import { RoutePath } from "./route-paths";
import HomePage from "./framework/pages/home-page/home-page";
import { compose } from "redux";
import { connect, useSelector, shallowEqual } from "react-redux";
import SignUpPage from "./framework/pages/signinup-page/signup-page";
import SignInPage from "./framework/pages/signinup-page/signin-page";
import { ToastsContainer, ToastsStore, ToastsContainerPosition } from "react-toasts";
import { selectCurrentUser } from "./framework/redux/user/user.selectors";
import AuthorizedPageSwitch from "./framework/pages/authorized-page/authorized-page-switch";
import ResetPasswordPage from "./framework/pages/signinup-page/resetPassword-page";
import GlobalModal from "./framework/components/global-modal/global-modal";
import ConfirmEmailPage from "./framework/pages/confirm-email/confirmEmail-page";
import { RulesPage } from "./framework/pages/rules-page/rules-page";

interface Props {
  match: any;
  history?: History;
}

const App: React.FC<Props> = () => {
  const user = useSelector(selectCurrentUser, shallowEqual);

  return (
    <>
      <Switch>
        <Route exact path={RoutePath.HOME} render={() => (user && user.token) ? <Redirect to={RoutePath.HOME_PANEL} /> : <HomePage />} />
        <Route exact path={RoutePath.SIGNIN} render={() => (user && user.token) ? <Redirect to={RoutePath.HOME_PANEL} /> : <SignInPage />} />
        <Route exact path={RoutePath.SIGNUP} render={() => (user && user.token) ? <Redirect to={RoutePath.HOME_PANEL} /> : <SignUpPage />} />
        <Route exact path={RoutePath.RESETPASSWORD} render={() => (user && user.token) ?
          <Redirect to={RoutePath.HOME_PANEL} /> : <ResetPasswordPage />} />
        <Route path={RoutePath.AUTHORIZED} component={() => (user && user.token) ? <AuthorizedPageSwitch /> : <Redirect to={RoutePath.HOME} />} />
        <Route path={RoutePath.RULES} component={() => (user && user.token) ? <AuthorizedPageSwitch /> : <RulesPage />} />
        <Route exact path={RoutePath.CONFIRMEMAIL} render={ConfirmEmailPage} />
      </Switch>
      <ToastsContainer store={ToastsStore} position={ToastsContainerPosition.TOP_RIGHT} />
      <GlobalModal />
      <CookiePolicyPopup />
    </>
  );
}

export default compose(
  withRouter,
  connect(null, null)
)(App);