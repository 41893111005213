import React from "react";
import { useSelector, shallowEqual } from "react-redux";
import { selectSuggestlyEntities, selectCurrentUser } from "../../redux/user/user.selectors";
import { SuggestlyEntityDto } from "../../dto/suggestlyEntityDto";
import { UserDto } from "../../dto/user-dto";
import { UserRoleEnum } from "../../dto/user-role-enum";
import "./global-alert.scss";

const GlobalAlert: React.FC = () => {
  const suggestlyEntites = useSelector(selectSuggestlyEntities, shallowEqual) as Array<SuggestlyEntityDto>;
  const currentUser = useSelector(selectCurrentUser, shallowEqual) as UserDto;;


  const getMaxResults = (user: UserDto) => {
    let allRoles = user.roles.map(x => x.role);

    if (allRoles.indexOf(UserRoleEnum.Admin) > -1) {
      return 15;
    }
    else if (allRoles.indexOf(UserRoleEnum.ProUser) > -1) {
      return 15;
    }
    else if (allRoles.indexOf(UserRoleEnum.PlusUser) > -1) {
      return 5;
    }
    else if (allRoles.indexOf(UserRoleEnum.BasicUser) > -1) {
      return 1;
    }
    else {
      return 1;
    }
  }

  return (
    <>
      {getMaxResults(currentUser) < suggestlyEntites.length ?
        <div className="globalAlert">
          <h4>Wykryto krytyczny problem</h4>
          <p>Twoje konto zostało zawieszone</p>
          <p>Posiadasz obecnie więcej otwartych projektów, niż pozwala na to, Twój obecny plan cenowy.</p>
          <p>Aby kontynuować, przejdź na wyższy plan cenowy lub zredukuj liczbę projektów.</p>
          <p>Jeżeli nie wiesz co zrobić, skontaktuj się z administratorem.</p>
      </div> : null}
    </>
  )
}

export default GlobalAlert;
