import React from "react";
import Helmet from "react-helmet";
import { Container } from "react-bootstrap";
import DomainsConfigForm from "../../../../components/domains-config-form/domains-config-form";
import EmbedForm from "../../../../components/embed-form/embed-form";
import MainSection from "../../../../components/main-section/main-section";

const EmbedPanel: React.FC<any> = () => {
  return (
    <>
      <Helmet>
        <title>{'Embed | Suggestly'}</title>
      </Helmet>
      <div className="embed-panel">
        <Container fluid={false}>
          <MainSection
            title="Integraja"
            subtitle="Tutaj ustawisz domenę witryny, na której chcesz użyć Suggestly oraz informacje niezbędne do wykonania poprawnej integracji. Projekty Suggestly można umieszczać na statycznych stronach internetowych oraz w większości systemów cms."
          />
          <DomainsConfigForm />
          <EmbedForm />
        </Container>
      </div>
    </>
  )
}

export default EmbedPanel;