import React, { useState, useEffect } from "react";
import "./kalibracja-panel.scss";
import "./kalibracja-simple.scss";

import { useSelector, shallowEqual, useDispatch } from "react-redux";
import { updateWage } from "../../../../redux/wagesState/wagesState.actions";
import { selectInputsWages } from "../../../../redux/wagesState/wagesState.selectors";
import { InputWageDto, VariantWageDto, WageDto } from "../../../../dto/wageDto";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import { selectOutputs } from "../../../../redux/productState/productsState.selectors";
import { ApiRootPath } from "../../../../api/apiRootPath";
import { Button } from "react-bootstrap";
import { OutputDto } from "../../../../dto/outputDto";

const KalibracjaSimple: React.FC<any> = () => {
  const inputsWages = useSelector(selectInputsWages, shallowEqual) as Array<InputWageDto>
  const outputs = useSelector(selectOutputs, shallowEqual) as Array<OutputDto>
  const tabMapper = [0.0, 0.2, 0.4, 0.6, 0.8, 1.0, 1.2, 1.4, 1.6, 1.8, 2.0]
  const dispatch = useDispatch();
  const [activeOutput, setActiveProduct] = useState({} as OutputDto);
  const [activeInputWage, setActiveInputWage] = useState({} as InputWageDto);

  if (activeInputWage === undefined && inputsWages && inputsWages.length > 0) {
    setActiveInputWage(inputsWages[0]);
  }

  const activeInputsWagesIndex = inputsWages.findIndex(x => activeInputWage && x.inputId === activeInputWage.inputId) >= 0 ? inputsWages.findIndex(x => activeInputWage && x.inputId === activeInputWage.inputId) : 0;

  useEffect(() => {
    setActiveProduct(outputs[0])
    setActiveInputWage(inputsWages[0])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (activeInputWage && activeInputWage.inputId) {
      let activeIndex = inputsWages.findIndex(x => x.inputId === activeInputWage.inputId);
      setActiveInputWage(inputsWages[activeIndex]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [inputsWages])

  useEffect(() => {
    if (activeInputWage && activeInputWage.inputId) {
      let activeIndex = inputsWages.findIndex(x => x.inputId === activeInputWage.inputId);
      setActiveInputWage(inputsWages[activeIndex]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeOutput])

  const handleSelectWage = (value: number, wage: WageDto) => {
    dispatch(updateWage(wage.id, Number(value)))
  }

  const handleIncrementQuestion = (increment: boolean) => {
    let activeIndex = inputsWages.findIndex(x => x.inputId === activeInputWage.inputId);
    if (increment && activeIndex < inputsWages.length - 1) {
      setActiveInputWage(inputsWages[activeIndex + 1]);
    }
    else if (increment === false && activeIndex > 0) {
      setActiveInputWage(inputsWages[activeIndex - 1]);
    }
  }

  return (
    <div className="kalibracja-simple">
      {activeOutput ?
        <Row>
          <Col xl={{ span: 5 }}>
            <div className="productView">
              <p>Aktualnie kalibrujesz sugestię:</p>
              <h3>{activeOutput.name}</h3>
              {activeOutput.affiliationOutput ?
                <div className="product-img" style={{ backgroundImage: `url(${ApiRootPath.rootPath + activeOutput.affiliationOutput.mainImg})` }} />
                : null}
            </div>
            <div className="kalibracja-simple_productsNav">
              <p>Kalibruj inne sugestie</p>
              {outputs.map((output: OutputDto, index) => {
                return (
                  <div key={index} onClick={() => { setActiveProduct(output) }} className={output.id !== activeOutput.id ? "kalibracja-simple_productsNav-item" : "kalibracja-simple_productsNav-item active"}>
                    <span>{output.name}</span><i className="fa fa-sliders" aria-hidden="true"></i>
                  </div>
                )
              })}
            </div>
          </Col>
          <Col xl={{ span: 6, offset: 1 }} className="variants-col">
            {activeInputWage ? <>
              <div className="questions-nav-calibration">
                <Button
                  onClick={() => handleIncrementQuestion(false)}
                  disabled={activeInputsWagesIndex <= 0}
                >
                  Poprzednie
                </Button>
                <div className="questionsHeader">{`pytanie ${activeInputsWagesIndex + 1}/${inputsWages.length}`}</div>
                <Button
                  onClick={() => handleIncrementQuestion(true)}
                  disabled={inputsWages.length <= (activeInputsWagesIndex + 1)}
                >
                  Następne
                  </Button>
              </div>
              <h3>{activeInputWage.inputName}</h3>
              {activeInputWage.variants ? activeInputWage.variants.map((variant: VariantWageDto, index) => {
                let currentProductWageIndex = activeInputWage.outputs.findIndex(x => x.id === activeOutput.id);
                let currentWageIndex = activeInputWage.outputs[currentProductWageIndex].wages.findIndex(x => x.variantId === variant.id)
                // const wage = activeInputWage.products[currentProductWageIndex] ? activeInputWage.products[currentProductWageIndex].wages[currentWageIndex] : null;
                const wage = activeInputWage.outputs[currentProductWageIndex].wages[currentWageIndex];
                // if(wage == null) return <div className="calibrationCard" key={index}>Unexpected error, please contact with admin</div>
                return (
                  <div className="calibrationCard" key={index}>
                    <div className="calibrationCard_header">
                      <h4>{variant.title}</h4>
                      <div className="control-info-panel">
                        <i
                          className={wage.value < 0 ? "fa fa-ban active" : "fa fa-ban"}
                          aria-hidden="true"
                          onClick={() => { handleSelectWage(-9999, wage) }}
                        ></i>
                        <span className="currentValue">{wage.value >= 0 ? wage.value.toFixed(1) : "-----"} </span>
                        <i
                          className={wage.value > 2 ? "fa fa-bolt active" : "fa fa-bolt"}
                          aria-hidden="true"
                          onClick={() => { handleSelectWage(5, wage) }}
                        ></i>
                      </div>
                    </div>
                    <div className="stateDescription">
                      {wage.value > 2 ? <p>Zawyżone prawdopodobieństwo</p> :
                        (wage.value > 1.75 ? <p>Najwyższe prawdopodobieństwo</p> :
                          (wage.value > 1.5 ? <p>Wysokie prawdopodobieństwo</p> :
                            (wage.value > 1.1 ? <p>Wyższe prawdopodobieństwo</p> :
                              (wage.value > 0.9 ? <p>Umiarkowany wpływ</p> :
                                (wage.value > 0.5 ? <p>Niższe prawdopodobieństwo</p> :
                                  (wage.value > 0.25 ? <p>Niskie prawdopodobieństwo</p> :
                                    (wage.value >= 0 ? <p>Najniższe prawdopodobieństwo</p> :
                                      (wage.value < 0 ? <p>Produkt wykluczony</p> : null))))))))
                      }
                    </div>

                    <div className="calibSelectWrapper">
                      {tabMapper.map(((x: number, indexx) => {
                        return (
                          <div
                            key={indexx}
                            onClick={() => { handleSelectWage(x, wage) }}
                            className={wage.value >= x ? `calibSelect calibSelect${x * 10} filled` : `calibSelect calibSelect${x * 10}`}>
                            {x.toFixed(1)}
                          </div>
                        )
                      }))}
                    </div>
                  </div>
                )
              }) : null}
              <div className="questions-nav-calibration">
                <Button onClick={() => handleIncrementQuestion(false)}>Poprzednie</Button>
                <Button onClick={() => handleIncrementQuestion(true)}>Następne</Button>
              </div>
            </> : null}
          </Col>
        </Row>
        : null}
    </div >
  )
}

export default KalibracjaSimple;